var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"inside"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-body px-2 mb-2"},[_c('button',{staticClass:"justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1 text-primary",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fa fa-chevron-left fa-fw pull-left text-info"}),_vm._v(" Voltar ")])])])]),_vm._m(0)]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"flex-wrap col-acao pt-0 mt-0 col-lg-5"},[_c('div',{class:[_vm.largura < 500 ? 'card m-1' : 'card']},[_vm._m(1),_vm._l((_vm.cardOffers.paymentOptions),function(item,key2,index2){return _c('div',{key:`${key2}-${index2}`,staticClass:"card-body mb-2 pb-2 pt-0"},[_c('div',{staticClass:"row mx-0 px-1"},[_c('hr',{staticClass:"my-2"}),_vm._m(2,true),_c('div',{staticClass:"col-6 px-0 text-right"},[_c('p',{staticClass:"text-body mb-0",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.formatFloatToReal(parseFloat(item.value, 10)))+" ")])])]),_vm._m(3,true),_c('div',{staticClass:"row mx-0 px-1"},[_c('hr',{staticClass:"my-2"}),_vm._m(4,true),_c('div',{staticClass:"col-6 px-0 text-right"},[_c('p',{staticClass:"text-body mb-0"},[_vm._v(" "+_vm._s(item.installmentsNumber)+"x de "+_vm._s(_vm.formatFloatToReal(parseFloat(item.installmentValue, 10)))+" ")])])]),_c('div',{staticClass:"row mx-0 px-1"},[_c('hr',{staticClass:"my-2"}),_vm._m(5,true),_c('div',{staticClass:"col-6 px-0 text-right"},[_c('p',{staticClass:"text-body mb-0"},[_vm._v(" "+_vm._s(_vm.formatData(_vm.cardOffers.dueDate, "YYYY-MM-DD", "L"))+" ")])])]),_vm._m(6,true),_vm._m(7,true),(_vm.largura < 500)?_c('div',{staticClass:"pb-3",staticStyle:{"background-color":"#f5f7f8","border-radius":"3px"},style:(_vm.installmentsSelectedToShow.length > 5
                  ? 'height: 11rem; overflow-y: scroll;'
                  : '')},[_vm._m(8,true),_vm._l((_vm.installmentsSelectedToShow),function(value,key,index){return _c('div',{key:`${key}-${index}`,staticClass:"row mx-0 px-4",staticStyle:{"height":"20px"}},[_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"px-0 col-4 col-md-3 text-center"},[_c('p',{staticClass:"mb-0 mr-4"},[_vm._v(_vm._s(value.installmentNumber))])]),_c('div',{staticClass:"px-0 col-md-4 col-4 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatData(value.dueDate, "YYYY-MM-DD", "L"))+" ")])]),_c('div',{staticClass:"px-0 col-md-5 col-4 text-right"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatFloatToReal(parseFloat(value.originalValue, 10)))+" ")])])])})],2):_vm._e(),(_vm.largura > 500)?_c('div',{staticClass:"pb-3",staticStyle:{"background-color":"#f5f7f8","border-radius":"3px","overflow-x":"hidden"},style:(_vm.installmentsSelectedToShow.length > 5
                  ? 'height: 11rem; overflow-y: scroll;'
                  : '')},[_vm._m(9,true),_vm._l((_vm.installmentsSelectedToShow),function(value,key,index){return _c('div',{key:`${key}-${index}`,staticClass:"row mx-0 px-4",staticStyle:{"height":"20px"}},[_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"col-4 text-center"},[_c('p',{staticClass:"mb-0 mr-4"},[_vm._v(_vm._s(value.installmentNumber))])]),_c('div',{staticClass:"col-4 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatData(value.dueDate, "YYYY-MM-DD", "L"))+" ")])]),_c('div',{staticClass:"col-4 text-right"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatFloatToReal(parseFloat(value.originalValue, 10)))+" ")])])])})],2):_vm._e(),_c('hr'),_vm._m(10,true),_c('div',{staticClass:"row d-flex justify-content-center mt-3"},[_c('div',{staticClass:"text-center col-md-12"},[_c('button',{staticClass:"btn mb-4 btn-primary p-3",staticStyle:{"width":"100%","border-radius":"20px"},attrs:{"disabled":_vm.loadSetAgreement,"data-toggle":"modal","data-target":"#confirmAgreementModal"}},[_vm._v(" CONFIRMAR ACORDO ")])])]),(_vm.loadSetAgreement)?_c('div',{staticClass:"container"},[_c('ProgressLoading',{attrs:{"loading":_vm.loadSetAgreement}})],1):_vm._e()])})],2)])])]),_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#212540"},attrs:{"id":"confirmAgreementModal","tabindex":"-1","role":"dialog","aria-labelledby":"confirmAgreementModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content",style:(_vm.largura < 500
            ? 'margin: 30px !important;'
            : 'margin: 90px !important')},[_vm._m(11),_c('div',{staticClass:"modal-body"},[_c('p',{staticClass:"option-label text-bold"},[_vm._v(" Você deseja confirmar a oferta e criar o acordo? ")]),_c('div',{staticClass:"mt-3"},[_c('ProgressLoading',{attrs:{"loading":_vm.loadSetAgreement}})],1),(!_vm.loadSetAgreement)?_c('button',{staticClass:"btn btn-primary btn-block text-white mt-4",staticStyle:{"cursor":"pointer","border-radius":"0.9rem","min-height":"40px"},on:{"click":function($event){return _vm.fecharAcordo()}}},[_vm._v(" CONFIRMAR ACORDO ")]):_vm._e(),_c('div',{staticClass:"mt-3 text-center"},[(!_vm.loadSetAgreement)?_c('a',{staticStyle:{"cursor":"pointer","color":"#0046c0"},attrs:{"data-dismiss":"modal"}},[_vm._v(" CANCELAR ")]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"m-2"},[_c('h5',{},[_vm._v("Confirmar negociação")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header px-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-12"},[_c('h5',{staticClass:"text-body"},[_vm._v("Resumo da negociação")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 px-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Valor total do acordo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 px-1"},[_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"col-6 px-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Plano escolhido")])]),_c('div',{staticClass:"col-6 px-0 text-right"},[_c('p',{staticClass:"text-body mb-0",staticStyle:{"font-weight":"bold"}},[_vm._v(" À vista ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 px-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Condições")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 px-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Vencimento do acordo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 px-1"},[_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"col-6 px-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Formato de pagamento")])]),_c('div',{staticClass:"col-6 px-0 text-right"},[_c('p',{staticClass:"text-body mb-0"},[_vm._v(" PIX / Boleto ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 px-1"},[_c('div',{staticClass:"px-0 col-lg-12 col-12"},[_c('p',{staticClass:"mb-0"},[_vm._v("Parcelas negociadas:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pr-2 pl-2 pt-2 text-center"},[_c('div',{staticClass:"col-4"},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("Nº Parcela")])]),_c('div',{staticClass:"col-4"},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("Vencimento")])]),_c('div',{staticClass:"col-4"},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("Valor")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pr-2 pl-2 pt-2 text-center"},[_c('div',{staticClass:"col-4"},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("Nº Parcela")])]),_c('div',{staticClass:"col-4 text-center"},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("Vencimento")])]),_c('div',{staticClass:"col-4"},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("Valor")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-3 mb-1 px-2"},[_c('div',{staticClass:"col-lg-1 text-center px-1 col-1",staticStyle:{"align-content":"center"}},[_c('i',{staticClass:"fa fa-info-circle text-info"})]),_c('div',{staticClass:"px-1 col-lg-11 col-11"},[_c('p',{staticClass:"small"},[_vm._v(" O acordo realizado se refere as parcelas selecionadas. Caso não atualize todas as parcelas vencidas, as ações de cobrança e a negativação de seu CPF permanecerão. "),_c('br'),_c('br'),_vm._v(" Após o pagamento, o prazo de processamento da baixa é de até 2 (dois) dias uteis e o débito junto ao Serasa será baixado em até 5 (cinco) dias úteis. Para fins de reporte das informações do SCR junto ao Banco Central, esclarecemos que a atualização poderá levar até 60 (sessenta) dias. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"confirmAgreementModalLabel"}},[_vm._v(" Confirmar acordo ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
}]

export { render, staticRenderFns }